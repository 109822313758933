<template>
    <div class="ui grid m-0 p-0">
        <div class="ui grid row p-0 m-0">
            <sidebar/>
            <div class="thirteen wide column p-0">
                <navbar/>
                <div class="ui stackable grid m-0 px-1-5">
                    <div class="row">
                        <h3 class="text-blue-500">Statistiques general : </h3>
                    </div>
                    <div class="row filter-container">
                        <div class="filter-label">FILTRER PAR :</div>
                        <div class="ui form" style="width: 100%">
                            <div class="five fields">

                                <div class="field">
                                    <label>Cadre d'expertise</label>
                                    <v-select placeholder="Cadre d'expertise" :options="sorted_cadre_expertise" v-model="cadre_expertise"/>
                                </div>

                                <div class="field">
                                    <label>Status</label>
                                    <v-select placeholder="Status" :reduce="st => st._id"
                                              class="placeholder-color"
                                              :options="status_options"
                                              v-model="status"/>
                                </div>

                                <div class="field">
                                    <label>Date début</label>
                                    <date-milli v-model="dateDebutDossier"/>
                                </div>

                                <div class="field">
                                    <label>Date fin</label>
                                    <date-milli v-model="dateFinDossier"/>
                                </div>

                                <div class="field">
                                    <label>Compagnie</label>
                                    <v-select :reduce="ass => ass._id" label="label" placeholder="Compagnie"
                                            :options="assurance_list" v-model="assurance"/>
                                </div>


                            </div>
                            <!--<div style="float: right">
                                <button class="ui button" @click="getDossierPerDay">
                                    Filter
                                </button>
                            </div>-->

                            <div style="margin: 10px 0" class="flex justify-center">
                                <button class="ui positive right labeled icon button" style="border-radius: 6px;"
                                    @click="getDossierPerDay">
                                    <i class="search icon"></i>
                                    Filtrer
                                </button>
                                <!--                                            </div>-->

                                <button class="ui right floated button" style="border-radius: 6px;"
                                        @click="clearFilterDossierFields">
                                    Re-initialiser
                                </button>
                            </div>
                            <div style="clear: both"></div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="ten wide column">
                            <v-chart class="e-card" :options="dossier_options" :autoresize="true"/>
                        </div>
                        <div class="ui grid six wide column">
                            <div class="row">
                                <div class="wide column">
                                    <div class="ui fluid card statsCard">
                                        <div class="foldersCount"><p class="value">{{totalNet_average}}</p>
                                            <p class="label">MONTANT NET MOYEN DE TOUS LES DOSSIERS</p></div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="wide column">
                                    <div class="ui fluid card statsCard">
                                        <div class="foldersCount">
                                            <p class="label">Nombre des dossiers</p>
                                            <p class="value blue">{{total_dossier}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="ui divider"></div>
                    <div class="row pt-0">
                        <h3 class="text-blue-500">Statistiques par utilisateur : </h3>
                    </div>
                    <div class="row filter-container">
                        <div class="filter-label">FILTRER PAR :</div>
                        <div class="ui form" style="width: 100%">
                            <div class="three fields">

                                <div class="field">
                                    <label>l'utilisateur</label>
                                    <v-select placeholder="L'utilisateur" :reduce="user => user._id"
                                              class="placeholder-color" label="username"
                                              :options="user_list"
                                              v-model="selected_user"/>
                                </div>

                                <div class="field">
                                    <label>Date début</label>
                                    <date-milli v-model="dateDebutTask"/>
                                </div>

                                <div class="field">
                                    <label>Date fin</label>
                                    <date-milli v-model="dateFinTask"/>
                                </div>

                            </div>

                            <div style="margin: 10px 0" class="flex justify-center">
                                <button class="ui positive right labeled icon button" style="border-radius: 6px;"
                                        @click="getTasksPerDay(); getStats()">
                                    <i class="search icon"></i>
                                    Filtrer
                                </button>

                                <button class="ui right floated button" style="border-radius: 6px;"
                                        @click="clearFilterTaskFields">
                                    Re-initialiser
                                </button>
                            </div>
                            <div style="clear: both"></div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="ten wide column">
                            <v-chart class="e-card" :options="task_options" :autoresize="true"/>
                        </div>
                        <div class="ui grid six wide column">
                            <div class="row">
                                <div class="wide column">
                                    <div class="ui fluid card statsCard">
                                        <div class="foldersCount"><p class="value blue">{{stats.encours}}</p>
                                            <p class="label">Taches en cours</p></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="wide column">
                                    <div class="four wide column">
                                        <div class="ui fluid card statsCard">
                                            <div class="foldersCount"><p class="value red">{{stats.hors_delai}}</p>
                                                <p class="label">taches hors delais</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import navbar from '@/components/navbar.vue'
    import sidebar from '@/components/sidebar.vue'
    import Vue from 'vue'
    import ECharts from 'vue-echarts'
    import dateMilli from "@/components/dateMilli.vue"

    Vue.component('v-chart', ECharts)
    import 'echarts/lib/chart/line'
    import 'echarts/lib/chart/bar'
    import 'echarts/lib/component/dataZoom'
    import 'echarts/lib/component/legend'

    export default {
        name: "statistic",
        components: {
            'v-chart': ECharts,
            navbar, sidebar, dateMilli
        },
        data() {
            return {
                assurance_list: [],
                user_list: [],
                status_options: [
                    {_id: 'active', label: 'Active'},
                    {_id: 'cloturer', label: 'Clôturé'},
                    {_id: 'paye', label: 'Payé'},
                    {_id: 'suspended', label: 'Suspendu'},
                    {_id: 'cloturer_paye', label: 'Clôturé - Payé'},
                ],
                cadre_expertise_options: [
                    'RC', 'DTA', 'DC', 'BDG', 'AEJ', 'ARB',
                    'INC', 'VOL', 'DR', 'EC', 'TRPI'
                ],
                cadre_expertise: null,
                status: null,
                dateDebutDossier: null,
                dateFinDossier: null,
                dateDebutTask: null,
                dateFinTask: null,
                assurance: null,
                selected_user: null,
                stats: {
                    encours: 0,
                    hors_delai: 0
                },
                totalNet_average: 0,
                total_dossier: 0,
                dossier_options: {
                    color: ['#55A2D4', '#D53A35'],
                    legend: {
                        data: ['Barre', 'Courbe']
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            // interval: 0,
                            rotate: 30
                        },
                        axisTick: {
                            alignWithLabel: true
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    dataZoom: [
                        {
                            show: true,
                            realtime: true,
                            start: 70,
                            end: 100
                        }
                    ],
                    series: [
                        {
                            name: "Courbe",
                            data: [],
                            type: 'line',
                            smooth: true,
                        },
                        {
                            name: "Barre",
                            data: [],
                            type: 'bar',
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            barWidth: "40%",
                            smooth: true,
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(220, 220, 220, 0.8)'
                            }
                        }
                    ]
                },
                task_options: {
                    color: ['#55A2D4', '#D53A35'],
                    legend: {
                        data: ['Barre', 'Courbe']
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            // interval: 0,
                            rotate: 30
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    dataZoom: [
                        {
                            show: true,
                            realtime: true,
                            start: 70,
                            end: 100
                        }
                    ],
                    series: [
                        {
                            name: 'Courbe',
                            data: [],
                            type: 'line',
                            smooth: true,
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(220, 220, 220, 0.8)'
                            }
                        },
                        {
                            name: "Barre",
                            data: [],
                            type: 'bar',
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            barWidth: "40%",
                            smooth: true,
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(220, 220, 220, 0.8)'
                            }
                        }
                    ]
                },
            }
        },
        computed:{
            sorted_cadre_expertise(){
                return _.sortBy(this.$store.state.cadre_expertise)
            }
        },
        methods:{
            getDossierPerDay(){
                let match = {type:"dossier"}
                let match_average = {type:"dossier", nature: {$ne: "ARB"}}
                if(this.assurance){
                    match["assurance._id"] = this.assurance
                    match_average["assurance._id"] = this.assurance
                }

                if(this.status){
                    if(this.status === 'cloturer_paye'){
                        match.status = {$in: ['cloturer', 'paye']}
                        match_average.status = {$in: ['cloturer', 'paye']}
                    }
                    else{
                        match.status = this.status
                        match_average.status = this.status
                    }
                }
                else{
                    match_average.status =  {$in: ['cloturer', 'paye']}
                }

                if(this.cadre_expertise){
                    match.nature = this.cadre_expertise
                    match_average.nature = this.cadre_expertise
                }

                if(this.dateDebutDossier || this.dateFinDossier){
                    match.date_cloture = this.$store.dateToQuery(this.dateDebutDossier, this.dateFinDossier)
                    match_average.date_cloture = this.$store.dateToQuery(this.dateDebutDossier, this.dateFinDossier)
                }


                const data = {
                    collection: this.$SHARED.collections.documents,
                    pipeline: [
                        {
                            $facet: {
                                'average': [
                                    {$match: {...match_average, total_net: {$nin: [0, 1]}}},
                                    {
                                        $group: {
                                            _id: '$type',
                                            average: {$avg: "$total_net"},
                                            total: {$sum: 1}
                                        }
                                    },
                                ],
                                'stats': [
                                    {$match: match},
                                    {
                                        $addFields: {
                                            convertedDate: {
                                                $dateToString: {
                                                    format: "%d-%m-%Y",
                                                    date: {$toDate: "$date_creation"}
                                                }
                                            },
                                        }
                                    },
                                    {
                                        $group: {
                                            _id: "$convertedDate",
                                            count: {$sum: 1}
                                        }
                                    },
                                    {
                                        $addFields: {
                                            date_creation: { $toDate: "$_id" }
                                        }
                                    },
                                    {$sort: {'date_creation': 1}}
                                ]
                            }
                        },
                        {$unwind: {"path": "$average", preserveNullAndEmptyArrays: true}}
                    ]
                };
                this.$store.requestAggregate(data).then(result => {

                    if(result[0].average){
                        this.totalNet_average = result[0].average.average
                            ? result[0].average.average.toFixed(2) : "0.00"

                        this.total_dossier = result[0].average.total ? result[0].average.total : 0
                    }
                    else{
                        this.totalNet_average = "0.00"
                        this.total_dossier =  0
                    }

                    /*this.totalNet_average = (result[0].average && result[0].average.average)
                        ? result[0].average.average.toFixed(2) : "0.00"

                    this.total_dossier = (result[0].average && result[0].average.total) ? result[0].average.total : 0*/

                    this.dossier_options.series[0].data = []
                    this.dossier_options.series[1].data = []
                    result[0].stats.forEach((dossier)=>{
                        this.dossier_options.series[0].data.push([dossier._id, dossier.count])
                        this.dossier_options.series[1].data.push([dossier._id, dossier.count])
                    })
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'warn',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getTasksPerDay(){
                let match = {type:"link_task"}
                if(this.selected_user){
                    match['receiver._id'] = this.selected_user
                }

                if(this.dateDebutTask || this.dateFinTask)
                    match.date_cloture = this.$store.dateToQuery(this.dateDebutTask, this.dateFinTask)

                const data = {
                    collection: this.$SHARED.collections.documents,
                    pipeline: [
                        {$match: match},
                        {$sort: {"date_creation": 1}},
                        {
                            $addFields: {
                                convertedDate: {$dateToString: {format: "%d-%m-%Y", date: {$toDate: "$date_creation"}}}
                            }
                        },
                        {
                            $addFields: {
                                id_date: {$concat: ["$id_doc", " - ", "$convertedDate"]}
                            }
                        },
                        {
                            $group: {
                                _id: "$id_date",
                                date_format: {$first: "$convertedDate"}
                            }
                        },
                        {
                            $group: {
                                _id: "$date_format",
                                count: {$sum: 1}
                            }
                        },
                        {
                            $addFields: {
                                date_creation: { $toDate: "$_id" }
                            }
                        },
                        {$sort: {'date_creation': 1}}
                    ],
                    batchSize: 999999
                };
                this.$store.requestAggregate(data).then(result => {
                    this.task_options.series[0].data = []
                    this.task_options.series[1].data = []
                    result.forEach((dossier)=>{
                        this.task_options.series[0].data.push([dossier._id, dossier.count])
                        this.task_options.series[1].data.push([dossier._id, dossier.count])
                    })
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'warn',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getStats(){
                let now = new Date().getTime();
                let selective_query = {}
                if(this.selected_user){
                    selective_query['receiver._id'] = this.selected_user
                }

                if(this.dateDebutTask || this.dateFinTask)
                    selective_query.date_cloture = this.$store.dateToQuery(this.dateDebutTask, this.dateFinTask)

                let pipeline=[
                    {$match:{...selective_query, type:'link_task', 'action.task_status':'active'}},
                    {
                        $facet: {
                            'encours': [
                                {$match: {...selective_query, deadline_warn: {$gt: now}}},
                                {$count: 'total'}
                            ],
                            'hors_delai': [
                                {$match: {...selective_query, deadline: {$lt: now}}},
                                {$count: 'total'}
                            ]

                        }
                    },
                    {$unwind: {"path": "$encours", preserveNullAndEmptyArrays: true}},
                    {$unwind: {"path": "$hors_delai", preserveNullAndEmptyArrays: true}},
                    {
                        $project: {
                            "encours": "$encours.total",
                            "hors_delai": "$hors_delai.total"
                        }
                    },]
                this.$store.requestAggregate({collection:'documents', pipeline:pipeline}).then(result => {
                    this.stats.encours = result[0].encours ? result[0].encours : 0
                    this.stats.hors_delai = result[0].hors_delai ? result[0].hors_delai : 0
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'warn',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getAssurancesList() {
                log('getAssurancesList');
                const data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.assurance_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getUsers() {
                console.log('getUsers');
                const data = {query: {}, options: {page: 1, limit: -1, sort: {username: 1}}};
                this.$store.dispatchAsync(this.$SHARED.services.user.list, data).then(ar => {
                    this.user_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            clearFilterDossierFields(){
                this.cadre_expertise = null
                this.status = null
                this.dateDebutDossier = null
                this.dateFinDossier = null
                this.assurance = null
                this.getDossierPerDay();
            },
            clearFilterTaskFields(){
                this.selected_user = null
                this.dateDebutTask = null
                this.dateFinTask = null
                this.getTasksPerDay();
                this.getStats();
            }
        },
        mounted() {
            this.getDossierPerDay();
            this.getTasksPerDay();
            this.getStats();
            this.getAssurancesList();
            this.getUsers();
        }
    }
</script>

<style scoped>
    .e-card {
        background: #FFFFFF;
        -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        padding: 2em 1em;
    }

    .filter-container {
        padding: 1em !important;
        border: 2px solid #062a55;
        border-radius: 10px;
        position: relative !important;
    }

    .filter-label {
        position: absolute;
        top: -10px;
        font-weight: 700;
        font-size: 1em;
        background-color: white;
        padding: 0 0.5em;
    }
</style>